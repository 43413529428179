/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import { HttpHeaders } from '@angular/common/http';
//import { OidcSecurityValidation, ɵc } from 'angular-auth-oidc-client'; //ɵc = OidcDataService

//import 'classlist.js';  // Run `npm install --save classlist.js`.
import { environment } from 'projects/core/src/lib/environments/environment';

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
      enumerable: false,
      value: function(obj) {
          let newArr = this.filter(function(el) {
            return el == obj;
          });
          return newArr.length > 0;
        }
    });
  }

  // if(OidcSecurityValidation.prototype.validate_id_token_nonce){
  //   OidcSecurityValidation.prototype.validate_id_token_nonce = 
  //   // id_token C9: The value of the nonce Claim MUST be checked to verify that it is the same value as the one
  //   // that was sent in the Authentication Request.The Client SHOULD check the nonce value for replay attacks.
  //   // The precise method for detecting replay attacks is Client specific.
  //   // However the nonce claim SHOULD not be present for the refesh_token grant type
  //   // https://bitbucket.org/openid/connect/issues/1025/ambiguity-with-how-nonce-is-handled-on
  //   // The current spec is ambiguous and Keycloak does send it.
  //   /**
  //    * @param {?} dataIdToken
  //    * @param {?} localNonce
  //    * @param {?} ignoreNonceAfterRefresh
  //    * @return {?}
  //    */
  //   function (dataIdToken, localNonce, ignoreNonceAfterRefresh) {
  //       /** @type {?} */
  //       // var isFromRefreshToken = (dataIdToken.nonce === undefined || ignoreNonceAfterRefresh) && localNonce === OidcSecurityValidation.RefreshTokenNoncePlaceholder;
  //       // if (!isFromRefreshToken && dataIdToken.nonce !== localNonce) {
  //       //     this.loggerService.logDebug('Validate_id_token_nonce failed, dataIdToken.nonce: ' + dataIdToken.nonce + ' local_nonce:' + localNonce);
  //       //     return false;
  //       // }
  //       return true;
  //   };
  // } 

  // if (ɵc.prototype.getIdentityUserData) {
  //   ɵc.prototype.getIdentityUserData = function(url, token) {
  //     /** @type {?} */
  //     var headers = new HttpHeaders();
  //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
  //     /** @type {?} */
  //     //var data = "client_id=" + this.configurationProvider.openIDConfiguration.client_id + "&access_token=" + token;
  //     var data = "client_id=" + environment.authConfig.client_id + "&access_token=" + token;
  //     return this.httpClient.post(url, data, { headers: headers });      
  //   }
  // }

  /*
  //For test token expiration
  if(OidcSecurityValidation.prototype.validate_id_token_exp_not_expired){   
      OidcSecurityValidation.prototype.validate_id_token_exp_not_expired =      
      function (decoded_id_token, offsetSeconds) {
          var tokenExpirationDate = this.tokenHelperService.getTokenExpirationDate(decoded_id_token);
          offsetSeconds = offsetSeconds || 0;
          if (!tokenExpirationDate) {
              return false;
          }
          //set breakpoint, change test to true
          var test = false;
          var tokenExpirationValue
          if (test == false) {
            tokenExpirationValue = tokenExpirationDate.valueOf();
          }
          else {
            tokenExpirationValue = 1667556900000;
          }
          var nowWithOffset = new Date().valueOf() + offsetSeconds * 1000;
          var tokenNotExpired = tokenExpirationValue > nowWithOffset;
          this.loggerService.logDebug("Token not expired?: " + tokenExpirationValue + " > " + nowWithOffset + "  (" + tokenNotExpired + ")");
          // Token not expired?
          return tokenNotExpired;
      };
  }*/